import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import React from 'react';
import RoleRestrictedHOC from './auth/RoleRestrictedHOC';
import { getIdToken } from '../services/auth';

interface Props {
    isLoggedIn: boolean;
    user?: any;
    logout: () => void;
}

const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea');  // Create a <textarea> element
  el.value = str;                                 // Set its value to the string that you want copied
  el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px';                      // Move outside the screen to make it invisible
  document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
  const selection = document.getSelection();
  if (selection) {
    const selected =
      selection.rangeCount > 0        // Check if there is any content selected previously
        ? selection.getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      selection.removeAllRanges();    // Unselect everything on the HTML document
      selection.addRange(selected);   // Restore the original selection
    }
  }
};

const UserProfileDropdown = ({ isLoggedIn, user, logout }: Props) => {

  const copyJwtToClipboard = async () => {
    copyToClipboard(await getIdToken());
  };

  if (isLoggedIn) {
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    {
                        user?.attributes?.email ? user?.attributes?.email  : "Error"
                    }
                </DropdownToggle>
                <DropdownMenu right>
                    {/*<RoleRestrictedHOC visibleRoles={['admin']}>*/}
                      <DropdownItem onClick={copyJwtToClipboard}>
                        Copy API key
                      </DropdownItem>
                    {/*</RoleRestrictedHOC>*/}
                    <DropdownItem onClick={logout}>
                        Logout
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    } else {
        return null;
    }
};

export default UserProfileDropdown;