import React from 'react';
import { Container, Row } from 'reactstrap';

import Header from './Header';

import 'bootstrap/dist/css/bootstrap.css';
import './layout.css';
import {getCurrentUser} from "../services/auth";

interface Props {
    children?: any;
}

const Layout = ({ children }: Props) => {

    return (
        <div>
            <Header user={getCurrentUser()}/>
            <Container fluid>
                <Row className={'main'}>
                    {children}
                </Row>
            </Container>
        </div>
    );
};

export default Layout;
