import React from 'react';
import { isPermittedForUserRoles } from '../../services/auth';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {
    visibleRoles?: string[];
    isPreviewOnly?: boolean;
    children?: any;
}

const RoleRestrictedHOC = (props: Props) => {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          client
        }
      }
    }
  `);

    if (isPermittedForUserRoles(data.site.siteMetadata.client, props.visibleRoles ? props.visibleRoles : [], !!props.isPreviewOnly)) {
        return (props.children);
    } else {
        return null;
    }
};

export default RoleRestrictedHOC;