import React from "react";
import {Link} from "gatsby";
import {Nav, NavItem} from "reactstrap";

export interface SideMenuItem {
    title: string;
    link: string;
}

interface Props {
    menuItems: SideMenuItem[];
}

const Sidebar = ({ menuItems }: Props ) => {
    const itemsList = menuItems.map(function(item){
        return(
            <NavItem key={item.title}><Link to={item.link} activeClassName="active" className="nav-link">{item.title}</Link></NavItem>
        );
    });

    return (
        <Nav className={"sidebar"} vertical>
            {itemsList}
        </Nav>
    );
};


export default Sidebar;