import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {Nav, Navbar, NavbarBrand} from 'reactstrap';

import './header.css';
import LoggedInAwareNavbar from './LoggedInAwareNavbar';
import UserProfileDropdown from './UserProfileDropdown';
import {isLoggedIn, logout} from '../services/auth';

interface Props {
    user?: any;
}

const Header = ({user}: Props) => {

    const data = useStaticQuery(graphql`
    query {
        brandImage: file(relativePath: { eq: "maia.png" }) {
           childImageSharp {
              gatsbyImageData(layout: FIXED, height: 45, placeholder: NONE)
            }
        }
    }
  `);

    return (
        <Navbar color="dark" dark expand="md" fixed={'top'}>
            <NavbarBrand tag={Link} to={'/'}>
                <GatsbyImage image={data.brandImage.childImageSharp.gatsbyImageData} alt={'MAIA'}/>
            </NavbarBrand>
            <LoggedInAwareNavbar isLoggedIn={isLoggedIn()}/>
            <Nav className="mr-right" navbar>
                <UserProfileDropdown isLoggedIn={isLoggedIn()} user={user} logout={logout}/>
            </Nav>
        </Navbar>
    );
};

export default Header;
