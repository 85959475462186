import { Nav, NavItem } from 'reactstrap';
import { Link } from 'gatsby';
import React from 'react';
import RoleRestrictedHOC from './auth/RoleRestrictedHOC';

interface Props {
    isLoggedIn: boolean;
}

const isControlUrl = ({
                          location,
                      }: any) => {
    return location.pathname.startsWith('/app/control')
        ? { className: 'active nav-link' }
        : {};
};


const PartialNavLink = (props: any) => (
    <Link getProps={isControlUrl} {...props} />
);

const LoggedInAwareNavbar = ({ isLoggedIn }: Props) => {

    if (isLoggedIn) {
        return (
            <Nav className="mr-auto" navbar>
                <RoleRestrictedHOC visibleRoles={['admin']}>
                    <NavItem>
                        <PartialNavLink to="/app/control/status" className="nav-link"
                                        getProps={isControlUrl}>Control Plane</PartialNavLink>
                    </NavItem>
                </RoleRestrictedHOC>
                {/*<UncontrolledDropdown nav inNavbar>*/}
                {/*    <DropdownToggle nav caret>*/}
                {/*        {'Documentation'}*/}
                {/*    </DropdownToggle>*/}
                {/*    <DropdownMenu right>*/}
                {/*        <DropdownItem>*/}
                {/*            <Link to="/app/docs/user" className="nav-link">User</Link>*/}
                {/*        </DropdownItem>*/}
                {/*        <DropdownItem>*/}
                {/*            <Link to="/app/docs/developer" className="nav-link">Developer</Link>*/}
                {/*        </DropdownItem>*/}
                {/*        <DropdownItem divider />*/}
                {/*        <DropdownItem>*/}
                {/*            <Link to="/app/docs/internal" className="nav-link">Internal Documentation</Link>*/}
                {/*        </DropdownItem>*/}
                {/*        <DropdownItem divider />*/}
                {/*        <DropdownItem>*/}
                {/*            Engineering*/}
                {/*        </DropdownItem>*/}
                {/*        <DropdownItem>*/}
                {/*            Audit / Controls*/}
                {/*        </DropdownItem>*/}
                {/*    </DropdownMenu>*/}
                {/*</UncontrolledDropdown>*/}
                <NavItem>
                    <Link to="/app/downloads" activeClassName="active" className="nav-link">Downloads</Link>
                </NavItem>
              <RoleRestrictedHOC visibleRoles={['admin']}>
                <NavItem>
                  <a href={"/docs/index.html"} className="nav-link"
                                  >Documentation</a>
                </NavItem>
              </RoleRestrictedHOC>
            </Nav>
        );
    } else {
        return (
            <Nav className="mr-auto" navbar>
            </Nav>
        );
    }
};

export default LoggedInAwareNavbar;