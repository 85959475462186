import React from 'react';
import { Col } from 'reactstrap';
import Sidebar, { SideMenuItem } from './Sidebar';
import SEO from './SEO';


interface Props {
    sideMenuItems: SideMenuItem[];
    seoTitle: string;
    children?: any;
}

const DashboardPage = ({ sideMenuItems, seoTitle, children }: Props ) => {

    return (
        <div>
            <SEO title={seoTitle}/>
            <Sidebar menuItems={sideMenuItems}/>
            <Col id={'contents'}>{children}</Col>
        </div>
    )
};

export default DashboardPage
